import { Button, Card, Divider, Input } from "antd";
import React, { useEffect, useState } from "react";
import { ListBankAccount } from "../../components/list/ListBankAccount";
import { ListContact } from "../../components/list/ListContact";

import { ListStockCategories } from "../../components/list/ListStockCategories";
import { ListStockColor } from "../../components/list/ListStockColor";
import { ListStockProducts } from "../../components/list/ListStockProducts";
import {
  IBanAccount,
  IContact,
  IStockCategory,
  IStockColor,
  IStockProduct,
} from "../../models";
import useDataFetcher from "../../utils/dataFetcher";
import useDataSender from "../../utils/dataSender";
import { Notification, NotificationType } from "../../utils/notification";
import {
  AddBankAccount,
  AddContact,
  AddStockCategory,
  AddStockColor,
  AddStockProuct,
  DeleteBankAccount,
  DeleteContact,
  DeleteStockCategory,
  DeleteStockColor,
  DeleteStockProuct,
  EditBankAccount,
  EditContact,
  EditStockColor,
  EditStockProuct,
  GetBankAccounts,
  GetContacts,
  GetMessages,
  GetStockCategories,
  GetStockColors,
  GetStockProducts,
  SetMessages,
} from "../../utils/request";

const { TextArea } = Input;

export const LayoutStockOptions: React.FunctionComponent = () => {
  const { isFetching, fetchData } = useDataFetcher();
  const { isSending, sendData } = useDataSender();

  const [categories, setCategories] = useState([] as IStockCategory[]);
  const [products, setProducts] = useState([] as IStockProduct[]);
  const [colors, setColors] = useState([] as IStockColor[]);
  const [bankAccounts, setBankAccount] = useState([] as IBanAccount[]);

  const [contacts, setContacts] = useState([] as IContact[]);

  const [topMessage, setTopMessage] = useState("");
  const [bottomMessage, setBottomMessage] = useState("");

  useEffect(() => {
    updateDataSources();
  }, []);

  function updateDataSources() {
    fetchData(GetStockCategories(), {
      onResolve(data: []) {
        setCategories(data);
      },
      onError(error) {
        new Notification(
          NotificationType.Error,
          "Ayarlar yüklenirken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });

    fetchData(GetStockProducts(), {
      onResolve(data: []) {
        data.sort((n1: IStockProduct, n2: IStockProduct) => {
          var n1CategoryId = n1.categoryId as string;
          var n2CategoryId = n2.categoryId as string;

          if (!n1CategoryId) n1CategoryId = "1";
          if (!n2CategoryId) n2CategoryId = "1";

          return n1CategoryId.localeCompare(n2CategoryId);
        });

        setProducts(data);
      },
      onError(error) {
        new Notification(
          NotificationType.Error,
          "Ayarlar yüklenirken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });

    fetchData(GetStockColors(), {
      onResolve(data: []) {
        setColors(data);
      },
      onError(error) {
        new Notification(
          NotificationType.Error,
          "Ayarlar yüklenirken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });

    fetchData(GetContacts(), {
      onResolve(data: []) {
        setContacts(data);
      },
      onError(error) {
        new Notification(
          NotificationType.Error,
          "Ayarlar yüklenirken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });

    fetchData(GetMessages(), {
      onResolve(data: []) {
        setTopMessage(
          data["top"] ? data["top"].replaceAll("<EndLine>", "\n") : ""
        );
        setBottomMessage(
          data["bottom"] ? data["bottom"].replaceAll("<EndLine>", "\n") : ""
        );
      },
      onError(error) {
        new Notification(
          NotificationType.Error,
          "Ayarlar yüklenirken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });

    fetchData(GetBankAccounts(), {
      onResolve(data: []) {
        setBankAccount(data);
      },
      onError(error) {
        new Notification(
          NotificationType.Error,
          "Ayarlar yüklenirken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });
  }

  function setMessages(top: string, bottom: string) {
    fetchData(SetMessages(top, bottom), {
      onResolve(data) {
        updateDataSources();
        new Notification(NotificationType.Success, "Mesaj düzenlendi").send();
      },
      onError(error) {
        new Notification(
          NotificationType.Error,
          "Mesaj düzenlenirken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });
  }

  // Category
  function onAddCategory(value: string, logoFile: any) {
    const formData = new FormData();
    formData.append("name", value);
    formData.append("file", logoFile);

    sendData(AddStockCategory(), formData, {
      onResolve(data) {
        updateDataSources();
        new Notification(NotificationType.Success, "Kategori eklendi").send();
      },
      onError(error) {
        new Notification(
          NotificationType.Error,
          "Kategori yüklenirken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });
  }

  function onRemoveCategory(id: string) {
    fetchData(DeleteStockCategory(id), {
      onResolve(data: []) {
        updateDataSources();
        new Notification(NotificationType.Success, "Kategori silindi").send();
      },
      onError(error) {
        new Notification(
          NotificationType.Error,
          "Kategori silinirken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });
  }

  // Product
  function onAddProduct(
    editId: string,
    displayName: string,
    category: IStockCategory
  ) {
    var url = AddStockProuct(displayName, category.id);
    if (editId && editId != null)
      url = EditStockProuct(editId, displayName, category.id);

    fetchData(url, {
      onResolve(data) {
        updateDataSources();
        new Notification(
          NotificationType.Success,
          "Kategori ürünü " + (editId ? "düzenlendi" : "eklendi")
        ).send();
      },
      onError(error) {
        console.log(error);
        new Notification(
          NotificationType.Error,
          "Kategori ürünü eklenirken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });
  }

  function onRemoveProduct(id: string) {
    fetchData(DeleteStockProuct(id), {
      onResolve(data) {
        updateDataSources();
        new Notification(
          NotificationType.Success,
          "Kategori ürünü silindi"
        ).send();
      },
      onError(error) {
        console.log(error);

        new Notification(
          NotificationType.Error,
          "Kategori ürünü eklenirken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });
  }

  // Color
  function onAddColor(
    editId: string,
    displayName: string,
    colorPalette: string
  ) {
    var url = AddStockColor(displayName, colorPalette);
    if (editId && editId != null)
      url = EditStockColor(editId, displayName, colorPalette);

    fetchData(url, {
      onResolve(data) {
        updateDataSources();
        new Notification(
          NotificationType.Success,
          "Renk " + (editId ? "düzenlendi" : "eklendi")
        ).send();
      },
      onError(error) {
        console.log(error);
        new Notification(NotificationType.Error, "Renk eklenirken hata oluştu")
          .setDuration(2)
          .send();
      },
    });
  }

  function onRemoveColor(id: string) {
    fetchData(DeleteStockColor(id), {
      onResolve(data) {
        updateDataSources();
        new Notification(NotificationType.Success, "Renk silindi").send();
      },
      onError(error) {
        console.log(error);

        new Notification(NotificationType.Error, "Renk eklenirken hata oluştu")
          .setDuration(2)
          .send();
      },
    });
  }

  // Contact
  function onAddContact(editId: string, fullName: string, phoneNumber: string) {
    var url = AddContact(fullName, phoneNumber);
    if (editId && editId != null)
      url = EditContact(editId, fullName, phoneNumber);

    fetchData(url, {
      onResolve(data) {
        updateDataSources();
        new Notification(
          NotificationType.Success,
          "Kişi " + (editId ? "düzenlendi" : "eklendi")
        ).send();
      },
      onError(error) {
        console.log(error);
        new Notification(NotificationType.Error, "Kişi eklenirken hata oluştu")
          .setDuration(2)
          .send();
      },
    });
  }

  function onRemoveContact(id: string) {
    fetchData(DeleteContact(id), {
      onResolve(data) {
        updateDataSources();
        new Notification(NotificationType.Success, "Kişi silindi").send();
      },
      onError(error) {
        console.log(error);

        new Notification(NotificationType.Error, "Kişi eklenirken hata oluştu")
          .setDuration(2)
          .send();
      },
    });
  }

  // Bank Account
  function onAddBankAccount(editId: string, displayName: string, iban: string) {
    var url = AddBankAccount(displayName, iban);
    if (editId && editId != null)
      url = EditBankAccount(editId, displayName, iban);

    fetchData(url, {
      onResolve(data) {
        updateDataSources();
        new Notification(
          NotificationType.Success,
          "Banka hesabı " + (editId ? "düzenlendi" : "eklendi")
        ).send();
      },
      onError(error) {
        console.log(error);
        new Notification(
          NotificationType.Error,
          "Banka hesabı eklenirken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });
  }

  function onRemoveBankAccount(id: string) {
    fetchData(DeleteBankAccount(id), {
      onResolve(data) {
        updateDataSources();
        new Notification(NotificationType.Success, "Kişi silindi").send();
      },
      onError(error) {
        console.log(error);

        new Notification(NotificationType.Error, "Kişi eklenirken hata oluştu")
          .setDuration(2)
          .send();
      },
    });
  }

  return (
    <div className="grid grid-cols-3 gap-2">
      <Card title="Kategoriler" style={{ minWidth: 500 }}>
        <ListStockCategories
          title="Kategoriler"
          loading={isSending || isFetching}
          categories={categories}
          add={onAddCategory}
          remove={onRemoveCategory}
        />
      </Card>
      <Card title="Ürünler" style={{ minWidth: 500 }}>
        <ListStockProducts
          title="Ürünler"
          loading={isSending || isFetching}
          categories={categories}
          products={products}
          add={onAddProduct}
          remove={onRemoveProduct}
        />
      </Card>
      <Card title="Renkler" style={{ minWidth: 500 }}>
        <ListStockColor
          title="Renkler"
          loading={isSending || isFetching}
          categories={categories}
          colors={colors}
          add={onAddColor}
          remove={onRemoveColor}
        />
      </Card>

      <Card title="Kişiler" style={{ minWidth: 500 }}>
        <ListContact
          title="Kişiler"
          loading={isSending || isFetching}
          contacts={contacts}
          add={onAddContact}
          remove={onRemoveContact}
        />
      </Card>

      <Card title="Banka Hesapları" style={{ minWidth: 500 }}>
        <ListBankAccount
          title="Banka Hesapları"
          loading={isSending || isFetching}
          accounts={bankAccounts}
          add={onAddBankAccount}
          remove={onRemoveBankAccount}
        />
      </Card>

      <Card title="Mesajlar" style={{ minWidth: 500 }}>
        <span className="font-medium">Üst Mesaj</span>
        <TextArea
          value={topMessage}
          onChange={(value: any) => {
            setTopMessage(value.target.value);
          }}
          style={{ height: 80, resize: "none" }}
        />
        <Divider></Divider>
        <span className="font-medium">Alt Mesaj</span>
        <TextArea
          value={bottomMessage}
          onChange={(value: any) => {
            setBottomMessage(value.target.value);
          }}
          style={{ height: 80, resize: "none" }}
        />
        <Divider></Divider>
        <Button
          type="primary"
          loading={isSending || isFetching}
          onClick={() => {
            var tm = "";
            var bm = "";
            topMessage.split("\n").forEach((element) => {
              if (element.length > 0) {
                tm += element + "<EndLine>";
              }
            });
            bottomMessage.split("\n").forEach((element) => {
              if (element.length > 0) {
                bm += element + "<EndLine>";
              }
            });
            if (tm.length > 0)
              tm = tm.substring(0, tm.length - "<EndLine>".length);
            else tm = "null";
            if (bm.length > 0)
              bm = bm.substring(0, bm.length - "<EndLine>".length);
            else bm = "null";

            setMessages(tm, bm);
          }}
        >
          Güncelle
        </Button>
      </Card>
    </div>
  );
};

import { Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import React from "react";

interface InputFileChooserProps {
  loading: boolean;
  buttonTitle: string;
  fileType: string;
  onChange: (value: any) => void;
}

export const InputFileChooser = (props: InputFileChooserProps) => {
  const fileInput = React.useRef();

  return (
    <div>
      <Button
        //variant="contained"
        loading={props.loading}
        title={props.buttonTitle}
        icon={<UploadOutlined />}
        color="primary"
        type="primary"
        onClick={() => {
          var a = fileInput.current as any;
          a.click();
        }}
      ></Button>

      <input
        ref={fileInput}
        accept={props.fileType}
        type="file"
        onChange={(e) => {
          props.onChange(e);
          (fileInput.current as any).value = "";
        }}
        style={{ display: "none" }}
      />
    </div>
  );
};

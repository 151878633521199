import { Button, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { moneyFormatterWithoutPenny } from "../../components/table/TableOrderList";

import { Image } from "antd";
import logo from "../../logo.png";
import {
  IBanAccount,
  IContact,
  IStock,
  IStockCategory,
  IStockColor,
  IStockProduct,
} from "../../models";
import useDataFetcher from "../../utils/dataFetcher";
import { Notification, NotificationType } from "../../utils/notification";
import {
  GetBankAccounts,
  GetContacts,
  GetMessages,
  GetStockCategories,
  GetStockColors,
  GetStockList,
  GetStockProducts,
  ViewStockCategoryImage,
} from "../../utils/request";
import { WhatsAppOutlined } from "@ant-design/icons";

import { toPng } from "html-to-image";

interface ITableData {
  category: IStockCategory;
  entries: ITableEntry[];
}

interface ITableEntry {
  product: IStockProduct;
  price: number;
  colors: IStockColor[];
}

export const LayoutStockPrint: React.FunctionComponent = () => {
  const { isFetching, fetchData } = useDataFetcher();
  const [collumnData, setCollumnData] = useState<any[][]>(undefined);
  const [contactData, setContactData] = useState<IContact[]>(undefined);
  const [messageData, setMessageData] = useState<string[]>(undefined);
  const [bankAccounts, setBankAccount] = useState<IBanAccount[]>(undefined);

  useEffect(() => {
    updateDataSources();
  }, []);

  function updateDataSources() {
    fetchData(GetBankAccounts(), {
      onResolve(data: []) {
        setBankAccount(data);
      },
      onError(error) {
        new Notification(
          NotificationType.Error,
          "Banka hesap listesi alınırken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });

    fetchData(GetStockProducts(), {
      onResolve(data: []) {
        var productData = [] as IStockProduct[];
        data?.forEach((element) => {
          var product = element as IStockProduct;
          productData[product.id] = product;
        });

        // Get Collumn List
        fetchData(GetStockCategories(), {
          onResolve(categoryData: any[]) {
            // Get Color List
            fetchData(GetStockColors(), {
              onResolve(colorData: any[]) {
                // Get Contact List
                fetchData(GetContacts(), {
                  onResolve(contactData: any[]) {
                    setContactData(contactData);

                    // Get Messages
                    fetchData(GetMessages(), {
                      onResolve(messageData: any[]) {
                        setMessageData(messageData);

                        // Get Stock List
                        fetchData(GetStockList(), {
                          onResolve(data: []) {
                            var tableDataList = [] as ITableData[];

                            categoryData.forEach((element) => {
                              tableDataList[element.id] = {
                                category: element,
                                entries: [],
                              };
                            });

                            data.forEach((element) => {
                              var stock = element as IStock;
                              var product = productData[stock.productId];
                              if (product && stock.amount > 0) {
                                var tableData = tableDataList[
                                  product.categoryId
                                ] as ITableData;
                                if (tableData) {
                                  var entryId = (stock.productId +
                                    "." +
                                    stock.price) as string;

                                  if (!tableData.entries[entryId]) {
                                    tableData.entries[entryId] = {
                                      product: product,
                                      price: stock.price,
                                      colors: [],
                                    };
                                  }
                                  var color = null as IStockColor;
                                  if (stock.colorId) {
                                    colorData.forEach((element) => {
                                      if (element.id == stock.colorId) {
                                        color = element;
                                      }
                                    });
                                  }
                                  if (!color)
                                    color = {
                                      id: "defaultColor",
                                      displayName: "Siyah",
                                      colorPalette: "black",
                                    };
                                  tableData.entries[entryId].colors.push(color);
                                }
                              }
                            });

                            var res = [] as any[][];
                            res[0] = [];
                            res[1] = [];
                            res[2] = [];

                            Object.values(tableDataList)
                              .filter((element) => {
                                return (
                                  Object.values(element.entries).length > 0
                                );
                              })
                              .forEach((element) => {
                                var minIndex = -1;
                                var min = 0;

                                for (let key in res) {
                                  if (res[key].length == 0) {
                                    minIndex = key as any as number;
                                    break;
                                  }

                                  var colSize = 0;

                                  let tableDataList = res[key] as ITableData[];
                                  tableDataList.forEach((element) => {
                                    var entries = Object.values(
                                      element.entries
                                    );

                                    var add = entries.length + 5;
                                    entries.forEach((element) => {
                                      if (
                                        element.product.displayName.length > 36
                                      ) {
                                        add += 1;
                                      }
                                    });

                                    colSize += entries.length + add;
                                  });

                                  if (minIndex == -1 || colSize < min) {
                                    minIndex = key as any as number;
                                    min = colSize;
                                  }
                                }

                                if (minIndex == -1) minIndex = 0;

                                res[minIndex].push(element);
                              });

                            setCollumnData(res);
                          },
                          onError(error) {
                            console.log(error);
                            new Notification(
                              NotificationType.Error,
                              "Stok listesi alınırken hata oluştu"
                            )
                              .setDuration(2)
                              .send();
                          },
                        });
                      },
                      onError(error) {
                        new Notification(
                          NotificationType.Error,
                          "Mesaj bilgisi alınırken hata oluştu"
                        )
                          .setDuration(2)
                          .send();
                      },
                    });
                  },
                  onError(error) {
                    new Notification(
                      NotificationType.Error,
                      "Kişiler listesi alınırken hata oluştu"
                    )
                      .setDuration(2)
                      .send();
                  },
                });
              },
              onError(error) {
                new Notification(
                  NotificationType.Error,
                  "Renk listesi alınırken hata oluştu"
                )
                  .setDuration(2)
                  .send();
              },
            });
          },
          onError(error) {
            new Notification(
              NotificationType.Error,
              "Kategori listesi alınırken hata oluştu"
            )
              .setDuration(2)
              .send();
          },
        });
      },
      onError(error) {
        new Notification(
          NotificationType.Error,
          "Ürün listesi alınırken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });
  }

  return (
    <div className="flex flex-col">
      {(isFetching ||
        !collumnData ||
        !contactData ||
        !messageData ||
        !bankAccounts) && (
        <div className="flex flex-col items-center">
          <span className="font-bold">Veriler işleniyor...</span>
          <Spin size="large" />
        </div>
      )}

      {!isFetching &&
        collumnData &&
        contactData &&
        messageData &&
        bankAccounts && (
          <>
            <div className="flex mb-2">
              <Button
                type="primary"
                onClick={() => {
                  /*let box = document.getElementById("content") as any;
                let width = box.offsetWidth;
                let height = box.offsetHeight;*/
                  toPng(document.getElementById("content"), {
                    cacheBust: true,
                    pixelRatio: 1.5,
                  })
                    .then((dataUrl) => {
                      const link = document.createElement("a");
                      link.download = "fiyatlistesi.png";
                      link.href = dataUrl;
                      link.click();
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                Resim Olarak Kaydet
              </Button>
            </div>
            <Content
              loading={isFetching}
              contacts={contactData}
              collumnData={collumnData}
              messages={messageData}
              bankAccounts={bankAccounts}
            />
          </>
        )}
    </div>
  );
};

interface PropsContent {
  loading: boolean;
  messages: string[];
  contacts: IContact[];
  bankAccounts: IBanAccount[];
  collumnData: any[][];
}

const Content: React.FunctionComponent<PropsContent> = (
  props: PropsContent
) => {
  function getSize(displayName: string): number {
    if (displayName == "APPLE" || displayName == "APPLE AKSESUAR") return 30;
    else if (displayName == "SAMSUNG") return 150;
    else if (displayName == "TCL") return 75;
    else if (displayName == "GENERAL MOBİLE") return 200;
    else if (displayName == "POCO") return 50;
    else if (displayName == "DİJİKİD") return 50;
    else if (displayName == "XIAOMI") return 40;
    else if (displayName == "HUAWEI") return 60;
    else if (displayName == "XIAOMI") return 40;
    else if (displayName == "XIAOMI") return 40;
    else if (displayName == "XIAOMI AKSESUAR") return 40;
    else if (displayName == "TABLET") return 60;
    else if (displayName == "DİĞER AKSESUAR") return 50;
    else if (displayName == "BİLGİSAYAR") return 50;
    else if (displayName == "TUŞLU TELEFON") return 40;
    return 100;
  }

  return (
    <div
      id="content"
      className="container flex flex-col p-2 space-y-4 max-w-7xl bg-white" // buradaki max-w-xl genişliği değiştiriyor
    >
      <Header contacts={props.contacts} />

      {props.messages["top"]?.length > 0 && (
        <div className="flex outline outline-2 outline-blue-400 rounded p-1">
          <span className="font-bold tracking-tight text-center w-full text-lg">
            {props.messages["top"]
              ?.split("<EndLine>")
              .map((message: string) => {
                {
                  return <div>{message}</div>;
                }
              })}
          </span>
        </div>
      )}

      <div className="flex space-x-4">
        {props.collumnData.map((value: any, index: number) => {
          var tableDataList = value as ITableData[];

          return (
            <div id={"id_" + index} className="flex w-full flex-col space-y-4">
              {tableDataList.map((tableData: ITableData) => {
                {
                  return (
                    <div>
                      <Image
                        width={getSize(tableData.category.displayName)}
                        src={ViewStockCategoryImage(
                          tableData.category.logoPath
                        )}
                      />
                      <Table tableData={tableData} />
                    </div>
                  );
                }
              })}
            </div>
          );
        })}
      </div>

      {props.messages["bottom"]?.length > 0 && (
        <div className="flex outline outline-2 outline-blue-400 rounded p-1">
          <span className="font-bold tracking-tight text-center w-full text-lg">
            {props.messages["bottom"]
              .split("<EndLine>")
              .map((message: string) => {
                {
                  return <div>{message}</div>;
                }
              })}
          </span>
        </div>
      )}

      <div className="flex outline outline-2 outline-blue-400 rounded p-1">
        <table className="font-bold tracking-tight text-base">
          <thead>
            <tr>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {props.bankAccounts.map((arr: IBanAccount) => {
              return (
                <tr>
                  <td>
                    <span style={{ marginRight: 10 }}>{arr.displayName}</span>
                  </td>
                  <td>
                    <span className="text-base text-red-600">{arr.iban}</span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const Header: React.FunctionComponent<{ contacts: IContact[] }> = (props: {
  contacts: IContact[];
}) => {
  var dayOfWeek = new Date().toLocaleString(window.navigator.language, {
    day: "numeric",
    weekday: "long",
    month: "long",
  });

  return (
    <div className="flex p-2 justify-between items-center outline outline-2 outline-blue-400 rounded text-base">
      <div className="flex flex-col">
        <span className="font-bold text-red-600">{dayOfWeek}</span>
        <span>Osmangazi Mh. Papatya Cd.</span>
        <span>No:22/A</span>
        <span className="font-bold">Esenyurt, İstanbul </span>
      </div>
      <div className="flex flex space-x-2 items-center">
        <img
          className=""
          style={{
            width: 804 * 0.55,
            height: 186 * 0.55,
          }}
          src={logo}
          alt="logo"
        />
      </div>
      <div>
        <table>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {props.contacts.map((arr: IContact) => {
              return (
                <tr className="text-xs font-bold text-base">
                  <td>
                    <WhatsAppOutlined style={{ paddingBottom: 5 }} />
                  </td>
                  <td>
                    <span style={{ marginRight: 10 }}>{arr.fullName}</span>
                  </td>
                  <td>
                    <span className="text-xs text-red-600 text-base">
                      {arr.phoneNumber}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const Table: React.FunctionComponent<{ tableData: ITableData }> = (props: {
  tableData: ITableData;
}) => {
  const sort = (n1: ITableEntry, n2: ITableEntry) => {
    if (n1.price > n2.price) return 1;
    if (n1.price < n2.price) return -1;
    return 0;
  };

  return (
    <table className="w-full border-seperate outline outline-2 rounded outline-blue-300">
      <thead
        className="outline outline-2 rounded outline-blue-400"
        style={{ backgroundColor: "rgb(0,0,128)" }}
      >
        <tr className="text-white">
          <th className="text-left w-4/6 pl-1 text-base">
            {props.tableData.category.displayName}
          </th>
          <th className="w-1/6">Renk</th>
          <th className="text-right w-1/6 pr-1">Fiyat</th>
        </tr>
      </thead>
      <tbody>
        {Object.values(props.tableData.entries)
          .sort(sort)
          .map((entry: ITableEntry, index: number) => {
            return (
              <tr
                className={
                  "font-bold " +
                  (index % 2 == 0
                    ? "bg-slate-200 border-b"
                    : "bg-white border-b")
                }
              >
                <td>{entry.product.displayName}</td>
                <td>
                  <div className="flex space-x-1">
                    {entry.colors.map((color: IStockColor) => {
                      return (
                        <div
                          style={{
                            backgroundColor: color.colorPalette,
                          }}
                          className={"w-3 h-3 rounded-full outline outline-1"}
                        />
                      );
                    })}
                  </div>
                </td>
                <td className="text-right">
                  {moneyFormatterWithoutPenny.format(entry.price)}
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

import { Button, Input, InputNumber } from "antd";
import React, { useState } from "react";
import { IStockCategory, IStockColor } from "../models";
import { Notification, NotificationType } from "../utils/notification";

export interface ArgsCreateStockColor {
  loading: boolean;
  categories: IStockCategory[];
  onCreate(editId: string, displayName: string, colorPalette: string): void;
  defaultCreateBarProbs?: IStockColor;
}

export const CreateStockColor: React.FunctionComponent<
  ArgsCreateStockColor
> = (props: ArgsCreateStockColor) => {

  var defaultPalette = props.defaultCreateBarProbs?.colorPalette;
  var defaultRGB = [];
  if (defaultPalette) {
    defaultPalette = defaultPalette.replace("rgb(", "").replace(")", "");
    defaultRGB = defaultPalette.split(",");
  }
  if (!defaultRGB[0]) defaultRGB[0] = 0;
  if (!defaultRGB[1]) defaultRGB[1] = 0;
  if (!defaultRGB[2]) defaultRGB[2] = 0;

  const [rgb, setRGB] = useState(defaultRGB);
  const inputRef = React.useRef();

  function onAdd() {
    var displayName = (inputRef.current as any).input.value;
    var R = rgb[0];//(inputRefR.current as any).value;
    var G = rgb[1];//(inputRefG.current as any).value;
    var B = rgb[2];//(inputRefB.current as any).value;

    if (!displayName) {
      new Notification(NotificationType.Error, "Renk ismi girmelisin")
        .setDuration(2)
        .send();
      return;
    }

    if (R == null || G == null || B == null){
      new Notification(NotificationType.Error, "Renk kodu girmelisin")
      .setDuration(2)
      .send();
    return;
    }

    props.onCreate(props.defaultCreateBarProbs?.id, displayName, "rgb(" + R + ", " + G + ", " + B + ")");
  }

  return (
    <div className="flex space-x-1 mb-4 items-center">
      <Input
        ref={inputRef}
        placeholder="Renk ismi gir"
        defaultValue={props.defaultCreateBarProbs?.displayName}
        style={{ maxWidth: 200 }}
      />

      <InputNumber
        value={rgb[0]}
        onChange={(value:any) => {
          let newArr = [...rgb]; 
          newArr[0] = value; 
          setRGB(newArr);
        }}
        min={0}
        max={255}
        controls={false}
        placeholder="R"
        style={{ maxWidth: 50 }}
      />

      <InputNumber
        value={rgb[1]}
        onChange={(value:any) => {
          let newArr = [...rgb]; 
          newArr[1] = value; 
          setRGB(newArr);
        }}
        min={0}
        max={255}
        controls={false}
        placeholder="G"
        style={{ maxWidth: 50 }}
      />

      <InputNumber
        value={rgb[2]}
        onChange={(value:any) => {
          let newArr = [...rgb]; 
          newArr[2] = value; 
          setRGB(newArr);
        }}
        min={0}
        max={255}
        controls={false}
        placeholder="B"
        style={{ maxWidth: 50 }}
      />

      <div
        style={{
            backgroundColor: "rgb(" + rgb[0] + "," + rgb[1] + "," + rgb[2] + ")",
        }}
        className={"w-3 h-3 rounded-full outline outline-1"}
      />

      <Button loading={props.loading} type="primary" onClick={onAdd}>
        {props.defaultCreateBarProbs ? "Düzenle" : "Ekle"}
      </Button>
    </div>
  );
};

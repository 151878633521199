import { List, Modal, Popconfirm } from "antd";
import React, { useState } from "react";
import { IContact } from "../../models";
import { BarCreateContact } from "../BarCreateContact";

export interface ArgsListContact {
  title: string;
  loading: boolean;
  contacts: IContact[];
  add(editId: string, fullName: string, phoneNumber: string): void;
  remove(id: string): void;
}

export const ListContact: React.FunctionComponent<ArgsListContact> = (
  props: ArgsListContact
) => {
  const [edit, setEdit] = useState<IContact>();

  function onCreate(id: string, fullName: string, phoneNumber: string) {
    setEdit(null);
    props.add(id, fullName, phoneNumber);
  }

  return (
    <div className="flex flex-col">
      <BarCreateContact
        loading={props.loading}
        contacts={props.contacts}
        onCreate={onCreate}
      />
      <List
        bordered
        dataSource={props.contacts}
        loading={props.loading}
        style={{ overflow: "auto", height: "420px" }}
        renderItem={(item) => (
          <List.Item
            actions={[
              <a
                onClick={() => {
                  setEdit(item);
                }}
              >
                Düzenle
              </a>,
              <Popconfirm
                title="Emin misin?"
                okText="Sil"
                cancelText="Kapat"
                onConfirm={() => props.remove(item.id)}
              >
                <a style={{ color: "red" }}>Sil</a>
              </Popconfirm>,
            ]}
          >
            <List.Item.Meta
              className="m-1"
              title={item.fullName}
              description={item.phoneNumber}
            />
          </List.Item>
        )}
      />

      {edit && (
        <Modal
          title={edit.fullName}
          closable={true}
          destroyOnClose={true}
          onCancel={() => {
            setEdit(null);
          }}
          visible={true}
          footer={null}
          style={{ minWidth: 600 }}
        >
          <BarCreateContact
            loading={props.loading}
            contacts={props.contacts}
            onCreate={onCreate}
            defaultCreateBarProbs={edit}
          />
        </Modal>
      )}
    </div>
  );
};

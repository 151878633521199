import { List, Modal, Popconfirm } from "antd";
import React, { useState } from "react";
import { IStockCategory, IStockColor } from "../../models";
import { CreateStockColor } from "../BarCreateStockColor";

export interface ArgsListStockColor {
  title: string;
  loading: boolean;
  categories: IStockCategory[];
  colors: IStockColor[];
  add(editId: string, displayName: string, colorPalette: string): void;
  remove(id: string): void;
}

export const ListStockColor: React.FunctionComponent<
  ArgsListStockColor
> = (props: ArgsListStockColor) => {
  const [editColor, setEditColor] = useState<IStockColor>();

  function onCreate(
    editId: string,
    displayName: string,
    colorPalette: string
  ) {
    setEditColor(null);
    props.add(editId, displayName, colorPalette);
  }

  return (
    <div className="flex flex-col">
      <CreateStockColor
        loading={props.loading}
        categories={props.categories}
        onCreate={onCreate}
      />

      <List
        bordered
        dataSource={props.colors}
        loading={props.loading}
        style={{ overflow: "auto", height: "420px" }}
        renderItem={(item) => (
          <List.Item
            actions={[
              <a
                onClick={() => {
                  setEditColor(item);
                }}
              >
                Düzenle
              </a>,
              <Popconfirm
                title="Emin misin?"
                okText="Sil"
                cancelText="Kapat"
                onConfirm={() => props.remove(item.id)}
              >
                <a style={{ color: "red" }}>Sil</a>
              </Popconfirm>,
            ]}
          >
           <div
          style={{
            backgroundColor: item.colorPalette,
          }}
          className={"w-3 h-3 rounded-full outline outline-1"}
          />
            <List.Item.Meta
              className="m-1"
              title={item.displayName}
              //description={buildCategoryDescription(item)}
            />
          </List.Item>
        )}
      />

    {editColor && (
        <Modal
          title={editColor.displayName}
          closable={true}
          destroyOnClose={true}
          onCancel={() => {
            setEditColor(null);
          }}
          visible={true}
          footer={null}
          style={{ minWidth: 600 }}
        >
          <CreateStockColor
            loading={props.loading}
            categories={props.categories}
            onCreate={onCreate}
            defaultCreateBarProbs={editColor}
          />
        </Modal>
      )}
      
    </div>
  );
};

import { Button, Input } from "antd";
import React from "react";
import { IBanAccount } from "../models";
import { Notification, NotificationType } from "../utils/notification";

export interface ArgsCreateBankAccount {
  loading: boolean;
  accounts: IBanAccount[];
  onCreate(editId: string, fullName: string, phoneNumber: string): void;
  defaultCreateBarProbs?: IBanAccount;
}

export const BarCreateBankAccount: React.FunctionComponent<
  ArgsCreateBankAccount
> = (props: ArgsCreateBankAccount) => {
  const inputRef = React.useRef();
  const inputRefIBAN = React.useRef();

  function onAdd() {
    var displayName = (inputRef.current as any).input.value;
    var iban = (inputRefIBAN.current as any).input.value;

    if (!displayName) {
      new Notification(NotificationType.Error, "İsim girmelisin")
        .setDuration(2)
        .send();
      return;
    }

    if (!iban) {
      new Notification(NotificationType.Error, "IBAN girmelisin")
        .setDuration(2)
        .send();
      return;
    }

    props.onCreate(props.defaultCreateBarProbs?.id, displayName, iban);
  }

  return (
    <div className="flex space-x-1 mb-4 items-center">
      <Input
        ref={inputRef}
        placeholder="İsim gir"
        defaultValue={props.defaultCreateBarProbs?.displayName}
        style={{ maxWidth: 200 }}
      />

      <Input
        ref={inputRefIBAN}
        placeholder="IBAN gir"
        defaultValue={props.defaultCreateBarProbs?.iban}
        style={{ maxWidth: 200 }}
      />

      <Button loading={props.loading} type="primary" onClick={onAdd}>
        {props.defaultCreateBarProbs ? "Düzenle" : "Ekle"}
      </Button>
    </div>
  );
};

import { List, Modal, Popconfirm } from "antd";
import React, { useState } from "react";
import { IBanAccount } from "../../models";
import { BarCreateBankAccount } from "../BarCreateBankAccount";

export interface ArgsListBankAccount {
  title: string;
  loading: boolean;
  accounts: IBanAccount[];
  add(editId: string, displayName: string, iban: string): void;
  remove(id: string): void;
}

export const ListBankAccount: React.FunctionComponent<ArgsListBankAccount> = (
  props: ArgsListBankAccount
) => {
  const [edit, setEdit] = useState<IBanAccount>();

  function onCreate(id: string, displayName: string, iban: string) {
    setEdit(null);
    props.add(id, displayName, iban);
  }

  return (
    <div className="flex flex-col">
      <BarCreateBankAccount
        loading={props.loading}
        accounts={props.accounts}
        onCreate={onCreate}
      />
      <List
        bordered
        dataSource={props.accounts}
        loading={props.loading}
        style={{ overflow: "auto", height: "420px" }}
        renderItem={(item) => (
          <List.Item
            actions={[
              <a
                onClick={() => {
                  setEdit(item);
                }}
              >
                Düzenle
              </a>,
              <Popconfirm
                title="Emin misin?"
                okText="Sil"
                cancelText="Kapat"
                onConfirm={() => props.remove(item.id)}
              >
                <a style={{ color: "red" }}>Sil</a>
              </Popconfirm>,
            ]}
          >
            <List.Item.Meta
              className="m-1"
              title={item.displayName}
              description={item.iban}
            />
          </List.Item>
        )}
      />

      {edit && (
        <Modal
          title={edit.displayName}
          closable={true}
          destroyOnClose={true}
          onCancel={() => {
            setEdit(null);
          }}
          visible={true}
          footer={null}
          style={{ minWidth: 600 }}
        >
          <BarCreateBankAccount
            loading={props.loading}
            accounts={props.accounts}
            onCreate={onCreate}
            defaultCreateBarProbs={edit}
          />
        </Modal>
      )}
    </div>
  );
};

import { Button, Card } from "antd";
import React, { useEffect, useState } from "react";
import { BarCreateStock } from "../../components/BarCreateStock";
import { InputFileChooser } from "../../components/input/InputFileChooser";

import { TableStockList } from "../../components/table/TableStockList";
import {
  IStock,
  IStockCategory,
  IStockColor,
  IStockProduct,
} from "../../models";
import useDataFetcher from "../../utils/dataFetcher";
import useDataSender from "../../utils/dataSender";
import { Notification, NotificationType } from "../../utils/notification";
import {
  CreateStock,
  DeleteStock,
  EditStock,
  GetStockCategories,
  GetStockColors,
  GetStockList,
  GetStockProducts,
  ImportExcellFile,
} from "../../utils/request";

export const LayoutStockList: React.FunctionComponent = () => {
  const { isFetching, fetchData } = useDataFetcher();
  const { isSending, sendData } = useDataSender();

  const [stockList, setStockList] = useState<IStock[]>();
  const [products, setProducts] = useState([] as IStockProduct[]);
  const [colors, setColors] = useState([] as IStockColor[]);
  const [categories, setCategories] = useState([] as IStockCategory[]);

  useEffect(() => {
    updateDataSources();
  }, []);

  function updateDataSources() {
    fetchData(GetStockList(), {
      onResolve(data: []) {
        setStockList(data);
      },
      onError(error) {
        new Notification(NotificationType.Error, "Stok listesi hata oluştu")
          .setDuration(2)
          .send();
      },
    });

    fetchData(GetStockProducts(), {
      onResolve(data: []) {
        data.sort((n1: IStockProduct, n2: IStockProduct) => {
          var n1CategoryId = n1.categoryId as string;
          var n2CategoryId = n2.categoryId as string;

          if (!n1CategoryId) n1CategoryId = "1";
          if (!n2CategoryId) n2CategoryId = "1";

          return n1CategoryId.localeCompare(n2CategoryId);
        });

        setProducts(data);
      },
      onError(error) {
        new Notification(
          NotificationType.Error,
          "Ayarlar yüklenirken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });

    fetchData(GetStockCategories(), {
      onResolve(data: []) {
        setCategories(data);
      },
      onError(error) {
        new Notification(
          NotificationType.Error,
          "Kategoriler yüklenirken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });

    fetchData(GetStockColors(), {
      onResolve(data: []) {
        setColors(data);
      },
      onError(error) {
        new Notification(
          NotificationType.Error,
          "Renkler yüklenirken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });
  }

  // Stock
  function createStock(
    productId: string,
    colorId: string,
    amount: number,
    price: number
  ) {
    fetchData(CreateStock(productId, colorId, amount, price), {
      onResolve(data) {
        updateDataSources();
        new Notification(NotificationType.Success, "Stok oluşturuldu").send();
      },
      onError(error) {
        new Notification(
          NotificationType.Error,
          "Stok oluşturulurken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });
  }

  function deleteStock(id: string) {
    fetchData(DeleteStock(id), {
      onResolve(data: []) {
        updateDataSources();
        new Notification(NotificationType.Success, "Kategori silindi").send();
      },
      onError(error) {
        new Notification(
          NotificationType.Error,
          "Kategori silinirken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });
  }

  function editStock(
    id: string,
    amount: number,
    price: number,
    colorId: string
  ) {
    fetchData(EditStock(id, amount, price, colorId), {
      onResolve(data: []) {
        updateDataSources();
        new Notification(NotificationType.Success, "Stok düzenlendi").send();
      },
      onError(error) {
        new Notification(
          NotificationType.Error,
          "Stok düzenlenirken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });
  }

  return (
    <Card>
      <InputFileChooser
        loading={isFetching || isSending}
        buttonTitle="Excell"
        fileType=".xlsx"
        onChange={(e) => {
          if (e.target.files.length > 0) {
            let file = e.target.files[0];
            const isXlsx =
              file.type ===
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            if (!isXlsx) {
              new Notification(
                NotificationType.Error,
                `${file.name} Xlsx dosyası değil` + file.type
              )
                .setDuration(2)
                .send();
              return;
            }
            const sizeCheck = file.size < 1024 * 1024;
            if (!sizeCheck) {
              new Notification(
                NotificationType.Error,
                `Dosya boyutu 1 MB'den büyük olamaz`
              )
                .setDuration(2)
                .send();
              return;
            }

            const formData = new FormData();
            formData.append("file", file);

            sendData(ImportExcellFile(), formData, {
              onResolve(data) {
                updateDataSources();
                new Notification(
                  NotificationType.Success,
                  "Verilen Excell dosyasından yüklendi"
                ).send();
              },
              onError(error) {
                //console.log(error.response);
                if (error.response.status == 409) {
                  var product = error.response["data"].prouct;
                  var color = error.response["data"].color;

                  var text =
                    "Excell dosyasında sistemde kayıtlı olmayan veriler kullanılmış";
                  if (product) {
                    text += " " + product;
                  }

                  if (color) {
                    text += " " + color;
                  }

                  new Notification(
                    NotificationType.Error,
                    "Excell dosyasında sistemde kayıtlı olmayan veriler kullanılmış. Ürün: " +
                      product
                  )
                    .setDuration(5)
                    .send();
                } else {
                  new Notification(
                    NotificationType.Error,
                    "Excell dosyası yüklenirken hata oluştu"
                  )
                    .setDuration(2)
                    .send();
                }
              },
            });
          }
        }}
      />

      <div className="flex flex-col mt-2 space-y-2">
        <BarCreateStock
          products={products}
          colors={colors}
          onCreate={createStock}
          update={updateDataSources}
        />
        <TableStockList
          loading={isFetching || isSending}
          products={products}
          categories={categories}
          colors={colors}
          stocks={stockList}
          deleteStock={deleteStock}
          editStock={editStock}
        />
      </div>
    </Card>
  );
};

import {
  AppstoreAddOutlined,
  SettingOutlined,
  TabletOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu } from "antd";
import { useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { urlToPageHeaderTitle } from "./utils/dataToComponent";
import { isTokensValid, removeTokens } from "./utils/jwt";
import { Notification, NotificationType } from "./utils/notification";
import { isOperator, setPermissions } from "./utils/permission";

const { Sider } = Layout;
const { SubMenu } = Menu;

function App() {
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("collapsed") != null
      ? localStorage.getItem("collapsed") == "true"
        ? true
        : false
      : false
  );

  const navigate = useNavigate();

  if (!isTokensValid()) return <Navigate to="/login" />;

  const onClick = (event) => {
    const key = event["key"];

    if (key == "menu-myorders") {
      navigate("myorders");
    } else if (key == "menu-mystats") {
      navigate("mystats");
    } else if (key == "admin-settings") {
      navigate("admin/settings");
    } else if (key == "admin-stats") {
      navigate("admin/stats");
    } else if (key == "admin-orders") {
      navigate("admin/orders");
    } else if (key == "admin-users") {
      navigate("admin/users");
    } else if (key == "stock-options") {
      navigate("stock/options");
    } else if (key == "stock-list") {
      navigate("stock/list");
    } else if (key == "stock-print") {
      navigate("stock/print");
    }
  };

  const logout = () => {
    removeTokens();
    setPermissions([]);
    new Notification(NotificationType.Success, "Çıkış yapıldı")
      .setDuration(1)
      .send();
    navigate("login");
  };

  //Menu.Item key="player-search" icon={<TeamOutlined />}>Bul</Menu.Item>

  return (
    <div id="app-screen" className="flex">
      <div id="app-sidebar" className="flex-none h-screen sticky top-0 left-0">
        <Sider
          collapsible
          collapsedWidth="48"
          collapsed={collapsed}
          onCollapse={() => {
            localStorage.setItem("collapsed", !collapsed);
            setCollapsed(!collapsed);
          }}
          className="h-full overflow-y-auto"
        >
          <Menu theme="dark" mode="inline">
            {/*
              <SubMenu
              key="menu"
              icon={<UserOutlined />}
              title="Menu"
              onClick={onClick}
            >
              <Menu.Item key="menu-myorders" icon={<ShoppingCartOutlined />}>
                Siparişlerim
              </Menu.Item>

              <Menu.Item key="menu-mystats" icon={<BarChartOutlined />}>
                İstatistiklerim
              </Menu.Item>
            </SubMenu>*/}

            {isOperator() && (
              <SubMenu
                key="stock-management"
                icon={<TabletOutlined />}
                title="Stok Yönetimi"
                onClick={onClick}
              >
                <Menu.Item key="stock-list" icon={<AppstoreAddOutlined />}>
                  Stok Listesi
                </Menu.Item>
                <Menu.Item key="stock-options" icon={<SettingOutlined />}>
                  Stok Ayarları
                </Menu.Item>
                <Menu.Item key="stock-print" icon={<PrinterOutlined />}>
                  Stoğu Yazdır
                </Menu.Item>
              </SubMenu>
            )}

            {/*isOperator() && (
              <SubMenu
                key="admin"
                icon={<SettingOutlined />}
                title="Admin"
                onClick={onClick}
              >
                <Menu.Item key="admin-orders" icon={<ShoppingCartOutlined />}>
                  Siparişler
                </Menu.Item>

                <Menu.Item key="admin-stats" icon={<BarChartOutlined />}>
                  İstatistikler
                </Menu.Item>

                <Menu.Item key="admin-settings" icon={<SettingOutlined />}>
                  Ayarlar
                </Menu.Item>

                <Menu.Item key="admin-users" icon={<UserOutlined />}>
                  Kullanıcılar
                </Menu.Item>
              </SubMenu>
            )*/}
          </Menu>
        </Sider>
      </div>

      <div
        id="app-viewport"
        className="flex flex-col grow"
        style={{ backgroundColor: "#F0F2F5", overflowX: "auto" }}
      >
        <header id="app-header" className="bg-white shadow">
          <div className="flex justify-between px-4 m-2">
            <span className="text-xl font-bold">
              {urlToPageHeaderTitle(window.location.href)}
            </span>
            <Button type="primary" danger onClick={logout}>
              Çıkış Yap
            </Button>
          </div>
        </header>
        <div id="app-content" className="flex flex-col mx-4 pt-4">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default App;

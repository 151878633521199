import { Button, Input } from "antd";
import React from "react";
import { IContact } from "../models";
import { Notification, NotificationType } from "../utils/notification";

export interface ArgsCreateContact {
  loading: boolean;
  contacts: IContact[];
  onCreate(editId: string, fullName: string, phoneNumber: string): void;
  defaultCreateBarProbs?: IContact;
}

export const BarCreateContact: React.FunctionComponent<ArgsCreateContact> = (
  props: ArgsCreateContact
) => {
  const inputRef = React.useRef();
  const inputRefPhoneNumber = React.useRef();

  function onAdd() {
    var fullName = (inputRef.current as any).input.value;
    var phoneNumber = (inputRefPhoneNumber.current as any).input.value;

    if (!fullName) {
      new Notification(NotificationType.Error, "İsim girmelisin")
        .setDuration(2)
        .send();
      return;
    }

    if (!phoneNumber) {
      new Notification(NotificationType.Error, "Telefon numarası girmelisin")
        .setDuration(2)
        .send();
      return;
    }

    props.onCreate(props.defaultCreateBarProbs?.id, fullName, phoneNumber);
  }

  return (
    <div className="flex space-x-1 mb-4 items-center">
      <Input
        ref={inputRef}
        placeholder="İsim soyisim gir"
        defaultValue={props.defaultCreateBarProbs?.fullName}
        style={{ maxWidth: 200 }}
      />

      <Input
        ref={inputRefPhoneNumber}
        placeholder="Telefon numarası gir"
        defaultValue={props.defaultCreateBarProbs?.phoneNumber}
        style={{ maxWidth: 200 }}
      />

      <Button loading={props.loading} type="primary" onClick={onAdd}>
        {props.defaultCreateBarProbs ? "Düzenle" : "Ekle"}
      </Button>
    </div>
  );
};

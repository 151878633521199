//const URL = "http://localhost:3256/api/";
const URL = "https://stok.yedi24teknoloji.com/api/";

export function GetStockList(): string {
  return URL + "stock/list";
}

export function CreateStock(
  productId: string,
  colorId: string,
  amount: number,
  price: number
): string {
  return (
    URL +
    "stock/createstock/" +
    productId +
    "/" +
    colorId +
    "/" +
    amount +
    "/" +
    price
  );
}

export function DeleteStock(id: string): string {
  return URL + "stock/delete/" + id;
}

export function EditStock(
  id: string,
  amount: number,
  price: number,
  colorId: string
): string {
  var editVariabes = "";
  if (amount >= 0) editVariabes += "amount=" + amount;
  if (price >= 0)
    editVariabes += (editVariabes.length > 0 ? "&" : "") + "price=" + price;
  if (colorId)
    editVariabes += (editVariabes.length > 0 ? "&" : "") + "colorId=" + colorId;
  if (editVariabes.length > 0) editVariabes = "?" + editVariabes;
  return URL + "stock/edit/" + id + editVariabes;
}

export function GetStockProducts(): string {
  return URL + "stock/products";
}

export function GetStockCategories(): string {
  return URL + "stock/categories";
}

export function GetStockColors(): string {
  return URL + "stock/colors";
}

export function GetContacts(): string {
  return URL + "stock/contacts";
}

export function GetBankAccounts(): string {
  return URL + "stock/bankaccounts";
}

export function AddBankAccount(displayName: string, iban: string): string {
  return URL + "stock/addbankaccount/" + displayName + "/" + iban;
}

export function EditBankAccount(
  id: string,
  displayName: string,
  iban: string
): string {
  return URL + "stock/editbankaccount/" + id + "/" + displayName + "/" + iban;
}

export function DeleteBankAccount(id: string): string {
  return URL + "stock/deletebankaccount/" + id;
}

export function AddContact(fullName: string, phoneNumber: string): string {
  return URL + "stock/addcontact/" + fullName + "/" + phoneNumber;
}

export function EditContact(
  id: string,
  fullName: string,
  phoneNumber: string
): string {
  return URL + "stock/editcontact/" + id + "/" + fullName + "/" + phoneNumber;
}

export function DeleteContact(id: string): string {
  return URL + "stock/deletecontact/" + id;
}

export function AddStockColor(
  displayName: string,
  colorPalette: string
): string {
  return URL + "stock/addcolor/" + displayName + "/" + colorPalette;
}

export function EditStockColor(
  id: string,
  displayName: string,
  colorPalette: string
): string {
  return URL + "stock/editcolor/" + id + "/" + displayName + "/" + colorPalette;
}

export function DeleteStockColor(id: string): string {
  return URL + "stock/deletecolor/" + id;
}

export function AddStockProuct(
  displayName: string,
  categoryId: string
): string {
  return URL + "stock/addproduct/" + displayName + "/" + categoryId;
}

export function EditStockProuct(
  id: string,
  displayName: string,
  categoryId: string
): string {
  return URL + "stock/editproduct/" + id + "/" + displayName + "/" + categoryId;
}

export function DeleteStockProuct(id: string): string {
  return URL + "stock/deleteproduct/" + id;
}

export function AddStockCategory(): string {
  return URL + "stock/addcategory";
}

export function DeleteStockCategory(id: string): string {
  return URL + "stock/deletecategory/" + id;
}

export function ViewStockCategoryImage(id: string): string {
  return URL + "stock/category/logoview/" + id + "/view";
}

export function AddWebsite(website: string): string {
  return URL + "api/website/add/" + website;
}

export function RemoveWebsite(website: string): string {
  return URL + "api/website/remove/" + website;
}

export function AddSeller(sellerName: string): string {
  return URL + "api/seller/add/" + sellerName;
}

export function RemoveSeller(sellerName: string): string {
  return URL + "api/seller/remove/" + sellerName;
}

export function AddProduct(product: string): string {
  return URL + "api/product/add/" + product;
}

export function RemoveProduct(product: string): string {
  return URL + "api/product/remove/" + product;
}

export function AddCompany(company: string): string {
  return URL + "api/company/add/" + company;
}

export function RemoveCompany(company: string): string {
  return URL + "api/company/remove/" + company;
}

export function GetOptions(options: string): string {
  return URL + "api/options/" + options;
}

export function AddOrder(): string {
  return URL + "api/order/create";
}

export function EditOrder(editingOrderId: string): string {
  return URL + "api/order/edit/" + editingOrderId;
}

export function RemoveOrders(orderIds: string[]): string {
  var ids = "";
  orderIds.forEach((element) => {
    ids += element + ",";
  });
  return URL + "api/order/remove/" + ids;
}

export function GetOrder(targetUser: string, filters?: string[]): string {
  var filter = "";
  if (filters) {
    var physicalInvoice = filters["physicalInvoice"];
    var website = filters["website"];
    var seller = filters["seller"];
    var company = filters["company"];
    var product = filters["product"];

    if (physicalInvoice) filter += "physicalInvoice=" + physicalInvoice;
    if (website) filter += "website=" + website;
    if (seller) filter += (filter.length > 0 ? "&" : "") + "seller=" + seller;
    if (company)
      filter += (filter.length > 0 ? "&" : "") + "company=" + company;
    if (product)
      filter += (filter.length > 0 ? "&" : "") + "product=" + product;

    if (filter.length > 0) filter = "?" + filter;
  }

  return URL + "api/order/get/" + targetUser + filter;
}

export function UpdateShipmentState(
  orderIds: string[],
  shipment: boolean
): string {
  var ids = "";
  orderIds.forEach((element) => {
    ids += element + ",";
  });
  return URL + "api/order/update/shipmentstate/" + ids + "/" + shipment;
}

export function UpdatePaidState(orderIds: string[], paid: boolean): string {
  var ids = "";
  orderIds.forEach((element) => {
    ids += element + ",";
  });
  return URL + "api/order/update/paidstate/" + ids + "/" + paid;
}

export function UploadInvoice(invoiceId: string): string {
  return URL + "api/order/invoice/upload/" + invoiceId;
}

export function UpdateIMEI(id: string, value: string): string {
  return URL + "api/order/update/imei/" + id + "/" + value;
}

export function RemoveInvoice(orderId: string): string {
  return URL + "api/order/invoice/remove/" + orderId;
}

export function ViewInvoice(invoiceId: string): string {
  return URL + "api/order/invoice/view/" + invoiceId + "/view";
}

export function SetInvoicePrinted(orderId: string, printed: boolean): string {
  return URL + "api/order/" + orderId + "/invoice/" + printed;
}

export function SetPhysicalInvoiceReceived(
  orderId: string,
  received: boolean
): string {
  return URL + "api/order/" + orderId + "/physicalinvoice/" + received;
}

export function DownloadInvoice(invoiceId: string): string {
  return URL + "api/order/invoice/view/" + invoiceId + "/download";
}

export function GetStats(targetUser: string): string {
  return URL + "api/order/stats/" + targetUser;
}

export function GetAdminList(): string {
  return URL + "users/list";
}

export function RegisterUser(): string {
  return URL + "auth/register";
}

export function AuthLogin(): string {
  return URL + "auth/login";
}

export function AuthRefreshToken(): string {
  return URL + "auth/refresh";
}

export function RemoveUser(username: string): string {
  return URL + "users/remove/" + username;
}

export function UpdateUser(username: string): string {
  return URL + "users/edit/" + username;
}

export function GetMessages(): string {
  return URL + "stock/getmessages";
}

export function SetMessages(top: string, bottom: string): string {
  return URL + "stock/setmessages/" + top + "/" + bottom;
}

export function ImportExcellFile(): string {
  return URL + "stock/import";
}
